<template>
  <div class="admin-page">
    <section class="section mt-0 bg-default" style="min-height: 450px"></section>

    <section class="section section-skew" style="margin-top: -500px">
      <div class="container" v-if="user.data">

        <div class="row">

          <div class="col-12">

            <tabs ref="adminTabs" pills fill type="primary" :active-tab="defaultTab" :value="defaultTab">
        
              <tab-pane key="statistics" title="statistics">
                  <template slot="title">
                      <i class="fad fa-tally mr-2"></i> Statistics
                  </template>

                  <admin-statistics :reflection-types="reflectionTypes"></admin-statistics>

              </tab-pane>
              <tab-pane key="notifications" title="notifications">
                  <template slot="title">
                    <i class="fad fa-bell mr-2"></i> Notifications
                  </template>
                  
                  <admin-notifications v-if="notificationsLoaded"></admin-notifications>

              </tab-pane>
              <tab-pane key="sponsorships" title="sponsorships">
                  <template slot="title">
                    <i class="fad fa-hands-helping mr-2"></i> Sponsored Users
                  </template>
                  
                  <admin-sponsorships v-if="sponsorshipLoaded"></admin-sponsorships>

              </tab-pane>
            </tabs>

          </div>

        </div>


      </div>

    </section>
  </div>
</template>

<script>
import BaseDropdown from "@/components/BaseDropdown";
import firebase from "firebase/app";
import { db } from "@/firebase";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import store from "@/store.js";
import { mapGetters } from "vuex";

import reflectionTypes from "@/models/reflectionTypes";

const AdminStatistics = () => import('@/components/admin/Statistics.vue');
const AdminNotifications = () => import('@/components/admin/Notifications.vue');
const AdminSponsorships = () => import('@/components/admin/Sponsorships.vue');

import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";

export default {
  name: "admin",
  metaInfo: {
    title: "Admin",
  },
  components: {
    flatPicker,
    BaseDropdown,
    AdminStatistics,
    AdminNotifications,
    AdminSponsorships,
    // Webhooks,
    // Groups,
    // Tags,
    Tabs,
    TabPane
  },

  data() {
    return {

      currentTab: 0,
      notificationsLoaded: false,
      sponsorshipLoaded: false,

      defaultTab: null,

      reflectionTypes

    };
  },

  mounted() {

    this.setDefaultTab();

    this.$watch(
        "$refs.adminTabs.activeTabIndex", (new_value, old_value) => {

            //execute your code here
            this.currentTab = new_value;
            
            if(this.currentTab === 1){
              this.notificationsLoaded = true;
            }
            if(this.currentTab === 2){
              this.sponsorshipLoaded = true;
            }

        }
    );

  },

  computed: {
    ...mapGetters({
      // map `this.user` to `this.$store.getters.user`
      // map `this.groups` to `this.$store.getters.groups`
      user: "user",
      groups: "groups",
    }),

  },

  watch: {
  },

  methods: {

    setDefaultTab(){

      if(this.$route.query && this.$route.query.tab){
        this.defaultTab = this.$route.query.tab;
      }else{
        this.defaultTab = 'statistics';
      }

    },

  },
};
</script>

<style scoped>
  .card-ellipse {
    position: absolute;
    text-align: right;
    top: 0;
    right: 0;
    z-index: 100;
  }

  .mr-logo {
    width: 90px !important;
    height: 90px !important;
  }
</style>
